function lazyLoadVideos() {
    const videos = document.querySelectorAll('[data-lazy-load]');

    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const video = entry.target;

                video.querySelectorAll('source').forEach(source => {
                    source.src = source.dataset.src;
                });

                video.setAttribute('preload', 'metadata');
                video.load();

                observer.unobserve(video);
            }
        });
    });

    videos.forEach(video => {
        observer.observe(video);
    });
}


export default lazyLoadVideos;
