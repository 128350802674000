class VideoLightBox {
    constructor() {
        this.openedVideo = null;

        this.wrapper = document.createElement('div');
        this.wrapper.className = 'hidden fixed inset-0 bg-black/10 backdrop-blur-sm flex justify-center items-center z-[1000] flex justify-center items-center';

        this.videoWrapper = document.createElement('div');
        this.videoWrapper.className = 'h-[90%] bg-white dark:bg-black shadow-lg relative';
        this.wrapper.appendChild(this.videoWrapper);

        this.closeButton = document.createElement('button');
        this.closeButton.className = 'z-10 cursor-pointer absolute -top-5 right-10 border bg-white dark:text-black border-purple-600 dark:border-white/25 rounded-xl px-5 py-2 hover:bg-purple-100 dark:hover:bg-purple-900 duration-100';
        this.closeButton.innerHTML = 'X';
        this.closeButton.addEventListener('click', () => {
            this.close();
        });
        this.videoWrapper.appendChild(this.closeButton);

        document.body.appendChild(this.wrapper);

        this.wrapper.addEventListener('click', (e) => {
            if (e.target === this.wrapper) {
                this.close();
            }
        });

        window.addEventListener('load', () => {
            // this.open(document.querySelector('video'));
        });

        window.addEventListener('keydown', (e) => {
            if (e.key === 'Escape') {
                this.close();
            }
        });
    }

    open(video) {

        if (this.openedVideo) {
            this.close();
        }

        this.openedVideo = video.cloneNode(true);
        this.openedVideo.className = 'w-full h-full object-contain';
        this.openedVideo.controls = true;
        this.openedVideo.removeAttribute('x-ref');

        this.openedVideo.addEventListener('loadedmetadata', () => {
            this.openedVideo.currentTime = video.currentTime;
            this.openedVideo.play();
        });

        this.wrapper.classList.remove('hidden');
        this.videoWrapper.appendChild(this.openedVideo);
        this.openedVideo.load();
    }

    close() {
        if (this.openedVideo) {
            this.openedVideo = null;
            this.wrapper.classList.add('hidden');
            this.videoWrapper.innerHTML = '';
        }
    }
}

export default VideoLightBox;
